import React, { useState } from 'react'
import { postEmployeeMessage } from '../../utils'
import { Formik, Form } from 'formik'
import { CustomSelect, Confirmation } from '.'
import { BioButton, BioCheckbox, BioTextInput } from '../shared'
import * as Yup from 'yup'

import * as styles from '../../css/molecules/bioEmployeeForm.module.scss'

import { getLocale, getLocaleUrl } from '../../utils/localeUtils'
export const BioEmployeeForm = ({ formData, sendMessage, errorMessage }) => {
  const locale = getLocale()
  const createInitialValues = formData => {
    let initial = {}
    for (const key in formData) {
      initial[key] = formData[key].initialValue || ''
    }

    initial.subject = false
    initial.confirmAge = false
    return initial
  }

  const employeeCardSchema = Yup.object().shape({
    subject: Yup.string().required('Pflichtfeld'),
    firstName: Yup.string().required('Pflichtfeld'),
    lastName: Yup.string().required('Pflichtfeld'),
    cardNumber: Yup.string(),
    membership: Yup.string(),
    privacy: Yup.boolean()
      .oneOf([true], 'Bitte stimmen Sie den Datenschutzbestimmungen zu')
      .required('Pflichtfeld'),
    confirmAge: Yup.boolean()
  })

  const [submitted, setSubmitted] = useState(false)
  const [submitMessage, setSubmitMessage] = useState('')
  const [isSending, setIsSending] = useState(true)

  const [initialValues, setInitialValues] = useState(
    createInitialValues(formData)
  )
  formData.subject.required = true
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={employeeCardSchema}
        onSubmit={async (values, { setSubmitting }) => {
          let formData = new FormData()
          setIsSending(true)
          Object.keys(values).forEach(key => {
            formData.append(key, values[key])
          })

          try {
            const res = await postEmployeeMessage(formData)
            res.response.status == 200
              ? setSubmitMessage(sendMessage)
              : setSubmitMessage(errorMessage)
          } catch (error) {
            setSubmitMessage(errorMessage)
          }

          setSubmitting(false)
          setIsSending(false)
          setSubmitted(true)
        }}>
        {({ values, errors, touched }) => {
          const touchedErrors = Object.entries(errors).find(
            ([k, v]) => touched[k]
          )

          const button = { buttonCta: 'Anfrage abschicken' }
          return (
            <Form id="employee-form" className={styles.bioEmployeeForm}>
              <section>
                <h2 className="bioHeading2">Ihr Anliegen</h2>

                <CustomSelect
                  {...formData.subject}
                  required
                  touchedErrors={touchedErrors}
                  type="select"
                />
              </section>

              <section>
                <h2 className="bioHeading2">Ihre Angaben</h2>

                <BioTextInput {...formData.firstName} />

                <BioTextInput {...formData.lastName} />

                <BioTextInput {...formData.cardNumber} />
              </section>

              <section>
                <h2 className="bioHeading5">
                  Bei Verlust der Karte nennen Sie uns bitte eine der folgenden
                  Angaben
                </h2>

                <BioTextInput {...formData.membership} />

                {/* Honeypot for form-security */}
                <BioCheckbox
                  type="checkbox"
                  {...formData.confirmAge}
                  label={'Ja, ich habe das 16. Lebensjahr vollendet'}
                  isForm={true}
                  name={'confirmAge'}
                />
                <BioCheckbox
                  type="checkbox"
                  {...formData.privacy}
                  isForm={true}
                />
              </section>

              <div className={styles.__submit} data-cy={'employee-submit'}>
                <BioButton type="submit" button={button} />
              </div>

              {touchedErrors?.length > 0 && errors && (
                <span className="bioError">
                  Es wurden fehlende oder falsche Daten angegeben. Bitte
                  überprüfen Sie Ihre Eingaben.
                </span>
              )}

              <Confirmation
                isSending={isSending}
                isSubmitted={submitted}
                setSubmitted={setSubmitted}
                headline={submitMessage}
                navigate={getLocaleUrl(locale, '/')}
              />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
